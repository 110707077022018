/* CSS Reset */
html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General Styles */
body {
  font-family: "Roboto", sans-serif;
  color: #2d2d2d;
  background-color: #f4f4f4;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 600;
  margin-bottom: 1em;
}

p {
  margin-bottom: 1em;
  line-height: 1.7;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* App Styles */
.App {
  max-width: 100%;
  margin: 0 auto;
  /* padding: 2em 0; */
}

/* Main Styles */
.main {
  margin: 2em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main h1,
.main h2,
.main h3,
.main p {
  margin-bottom: 1em;
}

/* Button Styles */
.cta-button {
  background-color: #c0b283;
  color: #000;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
}

.cta-button:hover {
  background-color: #9b8a6e; /* Darker gold */
}

/* Image Styles */
.responsive-image-connectImage,
.responsive-image-airobotImage,
.responsive-image-worldImage {
  width: 100%;
  display: block;
  margin-bottom: 2em;
}

/* Footer Styles */
footer {
  background-color: #212529;
  color: #f8f9fa;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  font-family: "Lato", sans-serif;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.footer-column {
  flex: 1;
}

.footer-column h4 {
  margin-bottom: 1rem;
  color: white;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  margin-bottom: 0.5rem;
}

footer a {
  color: #f8f9fa;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Make the footer responsive */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
  }

  .footer-column {
    text-align: center;
  }

  .footer-bottom {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .nav-links {
    flex-wrap: nowrap;
  }
}

@media (max-width: 360px) {
  .nav-links {
    flex-wrap: wrap;
  }
}

/* Testimonial Component */

.testimonials-container {
  background-color: #2a2a2a;
  color: #fff;
  padding: 2em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

.testimonials-container h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #c0b283; /* Luxury Gold */
  overflow: auto;
}

.testimonial-card {
  background-color: #1a1a1a;
  border-radius: 5px;
  padding: 5%;
  margin: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-around; /* evenly distributes space between items */
}

.testimonial-image img {
  border-radius: 50%;
  width: 40%; /* Make the image larger */
  height: 40%; /* Make the image larger */
  object-fit: cover;
  margin: auto; /* Center align the image */
  border: 3px solid; /* Add a gold border */
  border-color: #c0b283;
  box-sizing: border-box; /* Make sure the border doesn't increase the size of the image */
  margin-bottom: 5%;
}

.testimonial-text {
  font-size: 16px;
  line-height: 1.5;
  color: #ddd;
  margin-bottom: 1em;
}

.testimonial-author {
  font-size: 18px;
  font-weight: bold;
  color: #c0b283; /* Luxury Gold */
}

.slick-dots li button:before {
  color: #fff !important; /* Making the dots white */
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  opacity: 1; /* Full opacity for the active dot */
}

/* Styles for screens less than or equal to 768px */
@media screen and (max-width: 768px) {
  .testimonials-container h2 {
    font-size: 1.5em;
  }
}

/* CONTACT US*/
.Form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.Field {
  width: 100%;
  padding: 1vw; /* Change from 10px to viewport width unit */
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.ErrorMessage {
  color: red;
  margin-top: 5px;
  margin-bottom: 20px;
}

.button {
  margin-top: 100%;
  align-self: flex-end;
}

.Formik {
  flex: 1;
  margin: 2%;
  padding: 2%;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
/* Social Icons */
.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a {
  color: inherit;
  text-decoration: none;
}

.social-icons svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
  transition: fill 0.3s;
}

.social-icons a:hover svg {
  fill: #fff;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #fff;
}

.logo-main {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.logo-img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.section-content {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

h2 {
  color: #333;
  font-size: 28px;
  text-align: center;
}

button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.contact-section-layout {
  display: flex;
  flex-wrap: wrap; /* Added this to wrap items when space is less */
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; /* Aligns children to the top */
  width: 100%;
  height: 100%;
  height: 100vh;
  overflow: auto;
}

.App-contact {
  display: flex;
  height: 100vh;
  position: relative;
  font-family: "Lato", sans-serif;
  flex-wrap: wrap; /* Added this to wrap items when space is less */
}

.contact-content {
  /* width: 100%; */
  /* height: 100%; */
  background-color: #222222;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
}

.contact-form-container {
  background-color: #000;
  flex: 1 1 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Separates ContactForm and SubscriptionForm */
  align-items: center; /* Centers forms horizontally */
  padding: 1% 2%;
  overflow: auto; /* Change overflow to hidden */
}

.ContactForm {
  width: 100%;
  padding: 2%;
  /* border-radius: 10px; */
  /* margin: 1% 0; */
  background-color: #000; /* Dark Gray */
  flex: 1; /* Makes the box flexible */
}

.SubscriptionForm {
  height: 70%;
  width: 100%;
  padding: 2%;
  /* border-radius: 10px; */
  /* margin: 3% 0;  */
  background-color: #000; /* Black */
  flex: 1; /* Makes the box flexible */
  align-self: flex-start;
}

.contact-form-container h2 {
  font-size: 2em;
  margin: 0.1%;
  color: #fff;
}

.image-container {
  background: no-repeat center/cover;
  flex: 1 0 60%; /* Changed width to flex. This means the .image-container will grow and shrink flexibly and its base size is 60% */
  position: relative;
}

.responsive-image-ContactsImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-header {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-form-container form label {
  font-size: 1.2em;
  color: #fff; /* Change this to the color you want */
}

.error {
  color: #ff0000; /* Change this to the color you want */
}

.contact-form-container form input,
.contact-form-container form textarea {
  color: white;
  background-color: #929292df;
  padding: 1vw;
  margin-bottom: 2%;
  border: none;
  border-radius: 5px;
}
.message-textarea {
  height: 80px; /* Set this to your preferred height */
}
.contact-form-container form button {
  font-size: 1.2em;
  padding: 1vw 2vw;
  border: none;
  border-radius: 10px;
  color: #fff;
  background-color: #7a6c3b;
}

.contact-form-container form button:hover {
  background-color: #777;
}

/* Styles for screens less than or equal to 768px */
@media screen and (max-width: 768px) {
  .contact-form-container,
  .image-container {
    flex: 1 0 100%;
  }

  .contact-form-container {
    padding: 1% 2%;
  }

  .ContactForm {
    flex: 1 1 100%;
    padding: 2%;
    background-color: #222222; /* Dark Gray */
    flex: 1; /* Makes the box flexible */
  }

  .SubscriptionForm {
    height: 70%;
    flex: 1 1 100%;
    padding: 2%;
    background-color: #222222; /* Black */
    flex: 1; /* Makes the box flexible */
    align-self: flex-start;
  }

  .contact-form-container h2 {
    font-size: 1.5em;
    margin: 0.1%;
    color: #fff;
  }

  .contact-form-container form label {
    font-size: 0.8em;
    margin-top: 1%;
    margin-bottom: 0.5%;
  }

  .message-textarea {
    height: 20%; /* Set this to your preferred height */
  }

  .ContactForm {
    flex: 1 1 100%;
    padding: 2%;
    /* border-radius: 10px; */
    /* margin: 1% 0; */
    background-color: #222222; /* Dark Gray */
    flex: 1; /* Makes the box flexible */
  }

  .SubscriptionForm {
    height: 70%;
    flex: 1 1 100%;
    padding: 2%;
    /* border-radius: 10px; */
    /* margin: 3% 0;  */
    background-color: #222222; /* Black */
    flex: 1; /* Makes the box flexible */
    align-self: flex-start;
  }
}

/* ABOUT US SECTION */
/* about us section */
.App-about {
  display: flex;
  height: 100vh;
  color: #fff;
  position: relative;
  font-family: "Lato", sans-serif;
  overflow: auto;
}

.about-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap; /* Added this line */
}

.about-text {
  flex: 1 0 40%; /* Changed width to flex. This means the .about-text will grow and shrink flexibly and its base size is 40% */
  padding: 3em 3em 7em 5em;
  background: #2a2a2a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  color: #fff;
}

.about-text h4 {
  font-size: 1em;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c0b283;
}

.about-text h2 {
  font-size: 2.5em;
  font-weight: 700;
  margin: 0.5em 0;
  color: #fff;
}

.about-text p {
  font-size: 1.2em;
  line-height: 1.6;
  max-width: 80%;
}

.about-image {
  background: no-repeat center/cover;
  width: 60%;
  position: relative;
}

.location-tag {
  position: absolute;
  top: 2em;
  right: 2em;
  background: rgba(0, 0, 0, 0.7);
  padding: 1em;
  font-size: 1.2em;
  text-transform: uppercase;
}

.contact-button {
  font-size: 1.2em;
  padding: 1em 1.6em;
  border: none;
  border-radius: 4px;
  background-color: #7a6c3b;
  color: #ffffff;
  cursor: pointer;
  margin-top: 2em;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.contact-button:hover {
  background-color: #b9a76c;
}

/* Styles for screens less than or equal to 768px */
@media screen and (max-width: 768px) {
  .about-text,
  .about-image {
    flex: 1 0 100%;
    height: 50vh; /* Limit the maximum height */
  }
  .about-text h2 {
    font-size: 1.5em; /* Adjust the font size to your liking */
  }
  .about-text h4 {
    font-size: 0.8em; /* Adjust the font size to your liking */
  }
  .about-text p {
    font-size: 0.8em; /* Adjust the font size to your liking */
  }

  .contact-button {
    font-size: 0.8em;
    border: none;
    border-radius: 4px;
    background-color: #7a6c3b;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}

/* LOGO TEXT */
.logo-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  margin-left: 20px; /* Add some margin to move the logo text to the right */
}
.logo-text p {
  margin-top: 5px;
  margin-left: 0;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #fff;
}

.logo-img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

/* New Styles */
.logo .logo-text {
  font-weight: bold; /* Makes the text bold */
}

.logo:hover .logo-text {
  color: #c0b283; /* Changes the color of the text on hover */
}

/* Header Styles */
.App-header {
  display: flex;
  align-items: center; /* Add this to align items in the header vertically */
  justify-content: space-between;
  background-color: #000;
  padding: 20px 0;
  width: 100%;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 5px;
}

.nav-links a {
  color: #fff;
  margin: 0 1em;
  transition: color 0.2s ease-in-out;
}

.nav-links a:hover {
  color: #c0b283; /* Luxury Gold */
}

/* Styles for screens less than or equal to 768px */
@media screen and (max-width: 768px) {
  .App-header {
    display: flex;
    align-items: center; /* Add this to align items in the header vertically */
    justify-content: space-between;
    /* flex-direction: column; */
    padding: 0.1% 0;
    background-color: #000;
  }

  .logo-text p {
    font-size: 0.8em;
    padding: 5%;
    margin-right: 15%;
  }

  .nav-links a {
    font-size: 0.7em;
    color: #fff;
    margin: 0 0.2em;
    transition: color 0.2s ease-in-out;
  }
}

/* SERVICES SECTION */
.services-section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5% 10%; */
  padding: 2%;
  background-color: #000000; /* Change color as per your preference */
  height: 100vh;
  overflow: auto;
}

.services-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.services-image-container,
.services-text-container {
  flex: 1 1 40%; /* This allows for the content to take up equal widths and adjust as per the available space */
}

.services-image-container {
  flex: 1 0 60%; /* Changed width to flex. This means the .about-text will grow and shrink flexibly and its base size is 40% */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%; /* Add this to ensure the container takes the full height of the parent */
}

.responsive-image-servicesImage {
  min-width: 100%; /* The image will take up the full width of its parent container */
  min-height: 100%; /* This ensures the image takes the full height of its parent container */
  object-fit: cover; /* This scales the image as large as possible and maintains image aspect ratio (image doesn't get squished). */
  object-position: center; /* Change as needed */
}

.services-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  font-family: "Lato", sans-serif;
}

.services-text-container h2 {
  font-size: 2.5em;
  color: #c0b283;
  margin-bottom: 1rem;
}

.services-text-container p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 2rem;
}

.services-text-container ul {
  list-style: disc inside;
  padding-left: 1.5em;
  margin-bottom: 2rem;
}

.services-text-container ul li {
  font-size: 1.2em;
  line-height: 1.6;
  color: #ffffff;
}

.services-contact-button {
  font-size: 1.2em;
  padding: 1em 1.6em;
  border: none;
  border-radius: 4px;
  background-color: #7a6c3b;
  color: #ffffff;
  cursor: pointer;
  margin-top: 2em;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.services-contact-button:hover {
  background-color: #9a8c5b;
}

/* Styles for screens less than or equal to 768px */
@media screen and (max-width: 768px) {
  .services-text-container,
  .services-image-container {
    flex: 1 0 100%;
    height: 45vh; /* Limit the maximum height */
  }

  .responsive-image-servicesImage {
    min-width: 60%; /* The image will take up the full width of its parent container */
    min-height: 60%; /* This ensures the image takes the full height of its parent container */
    object-fit: cover; /* This scales the image as large as possible and maintains image aspect ratio (image doesn't get squished). */
    object-position: center; /* Change as needed */
  }

  .services-text-container h2 {
    font-size: 1.5em; /* Adjust the font size to your liking */
  }

  .services-text-container p {
    font-size: 0.8em;
    line-height: 1.6;
    color: #ffffff;
    margin-bottom: 2rem;
  }
  .services-text-container ul li {
    font-size: 0.8em;
    line-height: 1.6;
    color: #ffffff;
  }

  .services-contact-button {
    font-size: 0.8em;
    border: none;
    border-radius: 4px;
    background-color: #7a6c3b;
    color: #ffffff;
    cursor: pointer;
    margin-top: 0.1em;
    margin-bottom: 0.8em;
    transition: background-color 0.3s ease;
  }
}

/* Properties Section Styles */
.App-properties {
  background-color: #2b2b2b; /* Dark background */
  color: #f8f9fa; /* Light text color */
  padding: 2em;
  height: 100vh;
  display: flex;
  font-family: "Lato", sans-serif;
  justify-content: center;
  align-items: center;
}

.App-properties .text-container h2 {
  font-family: "Lato", sans-serif;
  color: #c0b283;
  font-size: 2.5em;
}

.App-properties .text-container p {
  font-family: "Lato", sans-serif;
  justify-content: center;
  display: flex;
}

.property-card {
  position: relative;
  width: 100%; /* Adjust as needed */
  max-width: 800px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.property-card img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners on image */
}

.property-card .overlay-top {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 1.5em; /* Large text */
  font-weight: bold;
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  padding: 5px 10px;
  border-radius: 4px;
}

.property-card .overlay-bottom {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 1em; /* Normal text size */
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  padding: 5px 10px;
  border-radius: 4px;
}


/* .logo-container {
  width: 150px;
  height: 50px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* If you want the SVG logo to fit entirely within the div container */
.logo-container svg {
  width: 360px;
  height: 250px;
  /* margin-left: 20px; */
  /* max-width: 100%;
  max-height: 100%; */
}

/* Styles for screens less than or equal to 768px */
@media screen and (max-width: 768px) {
  .App-properties .text-container h2 {
    font-size: 1.5em;
  }

  .logo-container svg {
    width: 200px;
    height: 120px;
    /* margin-left: 20px; */
    /* max-width: 100%;
    max-height: 100%; */
  }
}
